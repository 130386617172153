define("ember-aria-tabs/components/aria-tabs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-cached-decorator-polyfill", "@ember/object", "@ember/array", "@ember/runloop", "@glimmer/tracking", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _emberCachedDecoratorPolyfill, _object, _array, _runloop, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.className}} ...attributes>
    {{yield
      (hash
        tabList=(component
          "aria-tab-list"
          didInsertNode=this.didInsertTab
          disabledTabClassName=this.disabledTabClassName
          focus=this.focus
          onClick=this.handleClick
          onKeyUp=this.handleKeyUp
          panelIds=this.panelIds
          selectedIndex=(if
            this.isModeControlled @selectedIndex this.selectedIndex
          )
          selectedTabClassName=this.selectedTabClassName
          tabIds=this.tabIds
          willDestroyNode=this.willDestroyTab
        )
        tabPanel=(component
          "aria-tab-panel"
          didInsertNode=this.didInsertPanel
          forceRender=@forceRenderTabPanel
          panelIds=this.panelIds
          selectedIndex=(if
            this.isModeControlled @selectedIndex this.selectedIndex
          )
          selectedTabPanelClassName=this.selectedTabPanelClassName
          tabIds=this.tabIds
          willDestroyNode=this.willDestroyPanel
        )
      )
    }}
  </div>
  */
  {
    "id": "3rTbQabW",
    "block": "[[[11,0],[16,0,[30,0,[\"className\"]]],[17,1],[12],[1,\"\\n  \"],[18,4,[[28,[37,1],null,[[\"tabList\",\"tabPanel\"],[[50,\"aria-tab-list\",0,null,[[\"didInsertNode\",\"disabledTabClassName\",\"focus\",\"onClick\",\"onKeyUp\",\"panelIds\",\"selectedIndex\",\"selectedTabClassName\",\"tabIds\",\"willDestroyNode\"],[[30,0,[\"didInsertTab\"]],[30,0,[\"disabledTabClassName\"]],[30,0,[\"focus\"]],[30,0,[\"handleClick\"]],[30,0,[\"handleKeyUp\"]],[30,0,[\"panelIds\"]],[52,[30,0,[\"isModeControlled\"]],[30,2],[30,0,[\"selectedIndex\"]]],[30,0,[\"selectedTabClassName\"]],[30,0,[\"tabIds\"]],[30,0,[\"willDestroyTab\"]]]]],[50,\"aria-tab-panel\",0,null,[[\"didInsertNode\",\"forceRender\",\"panelIds\",\"selectedIndex\",\"selectedTabPanelClassName\",\"tabIds\",\"willDestroyNode\"],[[30,0,[\"didInsertPanel\"]],[30,3],[30,0,[\"panelIds\"]],[52,[30,0,[\"isModeControlled\"]],[30,2],[30,0,[\"selectedIndex\"]]],[30,0,[\"selectedTabPanelClassName\"]],[30,0,[\"tabIds\"]],[30,0,[\"willDestroyPanel\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@selectedIndex\",\"@forceRenderTabPanel\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\"]]",
    "moduleName": "ember-aria-tabs/components/aria-tabs.hbs",
    "isStrictMode": false
  });
  const MODE_CONTROLLED = 0;
  const MODE_UNCONTROLLED = 1;
  const DEFAULT_CLASS = 'ember-tabs';

  /**
   * The outer `<div />` of the tabs.
   *
   * If you specify additional props on the `<AriaTabs />` component they will be forwarded to the rendered `<div />`.
   *
   * Default CSS class: `ember-tabs`
   *
   * @class AriaTabs
   * @public
   */
  let AriaTabsComponent = _exports.default = (_class = class AriaTabsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "className", DEFAULT_CLASS);
      _defineProperty(this, "_tabIds", (0, _array.A)([]));
      _defineProperty(this, "_panelIds", (0, _array.A)([]));
      _initializerDefineProperty(this, "tabNodes", _descriptor, this);
      _initializerDefineProperty(this, "tabIds", _descriptor2, this);
      _initializerDefineProperty(this, "panelNodes", _descriptor3, this);
      _initializerDefineProperty(this, "panelIds", _descriptor4, this);
      _initializerDefineProperty(this, "previousMode", _descriptor5, this);
      _initializerDefineProperty(this, "selectedIndex", _descriptor6, this);
    }
    /**
     * Defaults to `false`.
     *
     * If set to `true` the tabs will be focused on initial render.
     * This allows immediate use of keyboard keys to switch tabs after the first render.
     *
     * @argument defaultFocus
     * @type Boolean
     * @default false
     */

    /**
     * Defaults to `0`.
     *
     * This allows changing the tab that should be open on initial render.
     * This is a zero-based index, so first tab is `0`, second tab is `1`, ...
     *
     * > This can only be used in uncontrolled mode when react-tabs handles the current selected tab internally and for this reason cannot be used together with `selectedIndex`.
     * > See `controlled vs uncontrolled mode` for more info on modes.
     *
     * @argument defaultIndex
     * @type Number
     * @default 0
     */

    /**
     * Defaults to `null`.
     *
     * This event handler is called every time a tab is about to change.
     * It will be called with the `index` that it will be changed to, the `lastIndex` which was selected before and the underlying `event` which is usually either a `keyup` or `click` event.
     * When `index` and `lastIndex` are equal it means the user clicked on the currently active tab.
     *
     * The callback can optionally return `false` to cancel the change to the new tab.
     *
     * > Returning `false` when the change to the new tab should be canceled is also important in controlled mode, as ember-aria-tabs still internally handles the focus of the tabs.
     *
     * > In controlled mode the `onSelect` handler is a required prop.
     *
     * @argument onSelect
     * @type Function
     * @default null
     */

    /**
     * Defaults to `null`.
     *
     * Set the currently selected tab. This is a zero-based index, so first tab is `0`, second tab is `1`, ...
     *
     * This enables controlled mode, which also requires `onSelect` to be set.
     * See `controlled vs uncontrolled mode` for more info on modes.
     *
     * @argument selectedIndex
     * @type Number
     * @default null
     */

    /**
     * Defaults to `"ember-tabs__tab--selected"`.
     *
     * Provide a custom class name for selected tabs.
     *
     * @argument selectedTabClassName
     * @type String
     * @default "ember-tabs__tab--selected"
     */
    get selectedTabClassName() {
      var _this$args$selectedTa;
      return (_this$args$selectedTa = this.args.selectedTabClassName) !== null && _this$args$selectedTa !== void 0 ? _this$args$selectedTa : "".concat(DEFAULT_CLASS, "__tab--selected");
    }

    /**
     * Defaults to `"ember-tabs__tab-panel--selected"`.
     *
     * Provide a custom class name for selected panels.
     *
     * @argument selectedTabPanelClassName
     * @type String
     * @default "ember-tabs__tab-panel--selected"
     */
    get selectedTabPanelClassName() {
      var _this$args$selectedTa2;
      return (_this$args$selectedTa2 = this.args.selectedTabPanelClassName) !== null && _this$args$selectedTa2 !== void 0 ? _this$args$selectedTa2 : "".concat(DEFAULT_CLASS, "__tab-panel--selected");
    }

    /**
     * Defaults to `"ember-tabs__tab--disabled"`.
     *
     * Provide a custom class name for disabled tabs.
     *
     * @argument disabledTabClassName
     * @type String
     * @default "ember-tabs__tab--disabled"
     */

    get disabledTabClassName() {
      var _this$args$disabledTa;
      return (_this$args$disabledTa = this.args.disabledTabClassName) !== null && _this$args$disabledTa !== void 0 ? _this$args$disabledTa : "".concat(DEFAULT_CLASS, "__tab--disabled");
    }

    /**
     * Defaults to `false`.
     *
     * By default only the current active tab will be rendered to DOM.
     * If set to `true` all tabs will be rendered to the DOM always.
     *
     * > This can also be enabled for each individual `<AriaTabPanel />` component with its prop `forceRender`.
     *
     * @argument forceRenderTabPanel
     * @type Boolean
     * @default false
     */

    get isModeControlled() {
      return this.mode === MODE_CONTROLLED;
    }
    get mode() {
      return (0, _utils.isNone)(this.args.selectedIndex) ? MODE_UNCONTROLLED : MODE_CONTROLLED;
    }

    // Ember 3.16
    // Need debounce to avoid double computation on the same loop
    updateTabIds() {
      this.tabIds = this._tabIds;
      this.didUpsert();
    }

    // Ember 3.16
    // Need debounce to avoid double computation on the same loop
    updatePanelIds() {
      this.panelIds = this._panelIds;
      this.didUpsert();
    }
    didInsertPanel(elementId, element) {
      this.panelNodes = (0, _array.A)([...this.panelNodes, element]);
      this._panelIds = (0, _array.A)([...this._panelIds, elementId]);
      (0, _runloop.debounce)(this, this.updatePanelIds, 0);
    }
    willDestroyPanel(elementId, element) {
      this.panelNodes = (0, _array.A)(this.panelNodes.without(element));
      this._panelIds = (0, _array.A)(this._panelIds.without(elementId));
      (0, _runloop.debounce)(this, this.updatePanelIds, 0);
    }
    didInsertTab(elementId, element) {
      this.tabNodes = (0, _array.A)([...this.tabNodes, element]);
      this._tabIds = (0, _array.A)([...this._tabIds, elementId]);
      (0, _runloop.debounce)(this, this.updateTabIds, 0);
    }
    willDestroyTab(elementId, element) {
      this.tabNodes = (0, _array.A)(this.tabNodes.without(element));
      this._tabIds = (0, _array.A)(this._tabIds.without(elementId));
      (0, _runloop.debounce)(this, this.updateTabIds, 0);
    }
    didUpsert() {
      if (!(0, _utils.isNone)(this.previousMode) && this.previousMode !== this.mode) {
        throw new Error("Switching between controlled mode (by using `selectedIndex`) and uncontrolled mode is not supported in `AriaTabs`.\nFor more information about controlled and uncontrolled mode of ember-aria-tabs see the README.");
      }
      if (this.mode === MODE_UNCONTROLLED) {
        const maxTabIndex = this.tabNodes.length - 1;
        let newSelectedIndex = null;
        if (this.selectedIndex != null) {
          newSelectedIndex = Math.min(this.selectedIndex, maxTabIndex);
        } else {
          newSelectedIndex = this.args.defaultIndex || 0;
        }
        this.selectedIndex = newSelectedIndex;
      } else {
        this.selectedIndex = this.args.selectedIndex;
      }
      this.previousMode = this.mode;
    }
    setSelected(index, event) {
      // Check index boundary
      if (index < 0 || this.tabNodes && index >= this.tabNodes.length) {
        return;
      }

      // Call change event handler
      if (typeof this.args.onSelect === 'function') {
        if (this.args.onSelect(index, this.selectedIndex, event) === false) {
          // Check if the change event handler cancels the tab change
          return;
        }
      }
      this.focus = event.type === 'keyup';
      if (this.mode === MODE_UNCONTROLLED) {
        this.selectedIndex = index;
      }
    }
    getNextTab(index) {
      const count = this.tabNodes.length;

      // Look for non-disabled tab from index to the last tab on the right
      for (let i = index + 1; i < count; i++) {
        if (!this.isTabDisabled(this.tabNodes[i])) {
          return i;
        }
      }

      // If no tab found, continue searching from first on left to index
      for (let i = 0; i < index; i++) {
        if (!this.isTabDisabled(this.tabNodes[i])) {
          return i;
        }
      }

      // No tabs are disabled, return index
      return index;
    }
    getPrevTab(index) {
      let i = index;

      // Look for non-disabled tab from index to first tab on the left
      while (i--) {
        if (!this.isTabDisabled(this.tabNodes[i])) {
          return i;
        }
      }

      // If no tab found, continue searching from last tab on right to index
      i = this.tabNodes.length;
      while (i-- > index) {
        if (!this.isTabDisabled(this.tabNodes[i])) {
          return i;
        }
      }

      // No tabs are disabled, return index
      return index;
    }
    getFirstTab() {
      const count = this.tabNodes.length;

      // Look for non disabled tab from the first tab
      for (let i = 0; i < count; i++) {
        if (!this.isTabDisabled(this.tabNodes[i])) {
          return i;
        }
      }
      return null;
    }
    getLastTab() {
      let i = this.tabNodes.length;

      // Look for non disabled tab from the last tab
      while (i--) {
        if (!this.isTabDisabled(this.tabNodes[i])) {
          return i;
        }
      }
      return null;
    }
    isTabDisabled(tabNode) {
      return tabNode.getAttribute('aria-disabled') === 'true';
    }
    handleClick(index, e) {
      if (this.isTabDisabled(this.tabNodes[index])) {
        return;
      }
      this.setSelected(index, e);
    }
    handleKeyUp(index, e) {
      let preventDefault = false;
      let useSelectedIndex = false;
      if (e.keyCode === 32 || e.keyCode === 13) {
        preventDefault = true;
        useSelectedIndex = false;
        this.setSelected(index, e);
      }
      if (e.keyCode === 37 || e.keyCode === 38) {
        // Select next tab to the left
        index = this.getPrevTab(index);
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 39 || e.keyCode === 40) {
        // Select next tab to the right
        index = this.getNextTab(index);
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 35) {
        // Select last tab (End key)
        index = this.getLastTab();
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 36) {
        // Select first tab (Home key)
        index = this.getFirstTab();
        preventDefault = true;
        useSelectedIndex = true;
      }

      // This prevents scrollbars from moving around
      if (preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }

      // Only use the selected index in the state if we're not using the tabbed index
      if (useSelectedIndex) {
        this.setSelected(index, e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tabNodes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tabIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "panelNodes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "panelIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "previousMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "mode", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "mode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsertPanel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyPanel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsertTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyUp"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AriaTabsComponent);
});