define("ember-concurrency/-private/external/scheduler/policies/keep-latest-policy", ["exports", "ember-concurrency/-private/external/scheduler/policies/bounded-policy", "ember-concurrency/-private/external/scheduler/policies/execution-states"], function (_exports, _boundedPolicy, _executionStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CANCELLED = (0, _executionStates.makeCancelState)("it belongs to a 'keepLatest' Task that was already running");

  // Given:
  // - started tasks: [a,b,_]
  // - queued tasks:  [c,d,e,f]
  // KeepLatest will cancel all but the last queued task instance, producing:
  // - started tasks: [a,b,c]
  // - queued tasks: [f]

  // TODO: perhaps we should expose another config for the number to keep enqueued.
  //       this would also make sense for enqueued, e.g. perform a max of maxConcurrency
  //       concurrent task instances, but after a number of queued instances has been
  //       reached, they should be cancelled.

  class KeepLatestReducer {
    constructor(remainingSlots, numToCancel) {
      this.remainingSlots = remainingSlots;
      this.numToCancel = numToCancel;
    }
    step() {
      if (this.remainingSlots > 0) {
        this.remainingSlots--;
        return _executionStates.STARTED;
      } else {
        if (this.numToCancel > 0) {
          this.numToCancel--;
          return CANCELLED;
        } else {
          return _executionStates.QUEUED;
        }
      }
    }
  }
  class KeepLatestPolicy extends _boundedPolicy.default {
    makeReducer(numRunning, numQueued) {
      let maxEnqueued = 1;
      let totalRunning = numRunning + numQueued;
      return new KeepLatestReducer(this.maxConcurrency, totalRunning - this.maxConcurrency - maxEnqueued);
    }
  }
  var _default = _exports.default = KeepLatestPolicy;
});