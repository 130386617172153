define("ember-noop/helpers/fn-if", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function fnIf(_ref /*, hash*/) {
    let [condition, invokeable] = _ref;
    if (condition) {
      return invokeable;
    }
    return () => {};
  });
});