define("ember-ajax-fetch/request", ["exports", "ember-ajax-fetch/fetch-request"], function (_exports, _fetchRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = request;
  /**
   * Helper function that allows you to use the default `ember-ajax-fetch` to make
   * requests without using the service.
   *
   * @class request
   * @public
   */
  function request(url, options) {
    const fetch = _fetchRequest.default.create();
    return fetch.request(url, options);
  }
});