define("ember-aria-tabs/components/aria-tab-panel", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/array", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberCachedDecoratorPolyfill, _array, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    aria-labelledby={{@tabId}}
    class={{this.classNames}}
    id={{this.elementId}}
    role="tabpanel"
    {{did-insert this.didInsertNode}}
    {{will-destroy this.willDestroyNode}}
    ...attributes
  >
    {{~#if this.shouldYield~}}
      {{yield (hash selected=this.selected)}}
    {{/if}}
  </div>
  */
  {
    "id": "ek7xbIVt",
    "block": "[[[11,0],[16,\"aria-labelledby\",[30,1]],[16,0,[30,0,[\"classNames\"]]],[16,1,[30,0,[\"elementId\"]]],[24,\"role\",\"tabpanel\"],[17,2],[4,[38,0],[[30,0,[\"didInsertNode\"]]],null],[4,[38,1],[[30,0,[\"willDestroyNode\"]]],null],[12],[41,[30,0,[\"shouldYield\"]],[[[18,3,[[28,[37,4],null,[[\"selected\"],[[30,0,[\"selected\"]]]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@tabId\",\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"yield\",\"hash\"]]",
    "moduleName": "ember-aria-tabs/components/aria-tab-panel.hbs",
    "isStrictMode": false
  });
  const DEFAULT_CLASS = 'ember-tabs__tab-panel';

  /**
   * A tab panel component rendered as `<div />`.
   *
   * If you specify additional props on the `<AriaTabPanel />` component they will be forwarded to the rendered `<div />`.
   *
   * Default CSS class: `ember-tabs__tab-panel`
   *
   * @class AriaTabPanel
   * @public
   */
  let AriaTabPanelComponent = _exports.default = (_class = class AriaTabPanelComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "elementId", _descriptor, this);
    }
    /**
     * Defaults to `"ember-tabs__tab-panel--selected"`.
     *
     * Provide a custom class name for the active tab panel.
     *
     * > This option can also be set for all `<AriaTabPanel />` components with the prop `selectedTabPanelClassName` on `<AriaTabs />`.
     *
     * @argument selectedClassName
     * @type String
     * @default "ember-tabs__tab-panel--selected"
     */
    get selectedClassName() {
      var _ref, _this$args$selectedCl;
      return (_ref = (_this$args$selectedCl = this.args.selectedClassName) !== null && _this$args$selectedCl !== void 0 ? _this$args$selectedCl : this.args.selectedTabPanelClassName) !== null && _ref !== void 0 ? _ref : "".concat(DEFAULT_CLASS, "--selected");
    }

    /**
     * Defaults to `false`.
     *
     * By default the tab content will only be rendered when the tab is active. If set to `true` the tab will also be rendered if inactive.
     *
     * > This can also be enabled for all `<AriaTabPanel />` components with the prop `forceRenderTabPanel` on `<AriaTabs />`.
     *
     * @argument forceRender
     * @type Boolean
     * @default false
     */

    get shouldYield() {
      return this.selected || this.args.forceRender;
    }
    get nodeIndex() {
      return (0, _array.A)(this.args.panelIds).indexOf(this.elementId);
    }
    get tabId() {
      return (0, _array.A)(this.args.tabIds)[this.nodeIndex];
    }
    get selected() {
      return this.nodeIndex === this.args.selectedIndex;
    }
    get classNames() {
      let classNames = [DEFAULT_CLASS];
      if (this.selected) {
        classNames.push(this.selectedClassName);
      }
      return classNames.join(' ');
    }
    didInsertNode(element) {
      this.elementId = element.id;
      if (typeof this.args.didInsertNode === 'function') {
        this.args.didInsertNode(this.elementId, ...arguments);
      }
    }
    willDestroyNode() {
      if (typeof this.args.willDestroyNode === 'function') {
        this.args.willDestroyNode(this.elementId, ...arguments);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "selectedClassName", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selectedClassName"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "elementId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "nodeIndex", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "nodeIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "tabId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selected", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "classNames", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "classNames"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsertNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AriaTabPanelComponent);
});