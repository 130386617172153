define("ember-collapsible-panel/services/panel-actions", ["exports", "@ember/object/computed", "@ember/array", "@ember/service", "@ember/object"], function (_exports, _computed, _array, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-assignment-of-untracked-properties-used-in-tracking-contexts, ember/no-classic-classes, ember/no-get, prettier/prettier */

  const State = _object.default.extend({
    name: null,
    boundOpenState: false,
    apiOpenState: false,
    apiWasUsed: false,
    isOpen: (0, _object.computed)('boundOpenState', 'apiOpenState', 'apiWasUsed', function () {
      if (this.get('apiWasUsed')) {
        return this.get('apiOpenState');
      } else {
        return this.get('boundOpenState');
      }
    }),
    animate: true,
    group: null
  });
  const Registry = _object.default.extend({
    init() {
      this._super(...arguments);
      this.keys = (0, _array.A)([]);
    },
    unknownProperty(name) {
      if (name === 'setUnknownProperty') {
        // For failing ember-default testing scenario
        // https://travis-ci.org/adopted-ember-addons/ember-collapsible-panel/builds/626881977
        return;
      }
      const state = State.create();
      this.get('keys').addObject(name);
      this.set(name, state); // eslint-disable-line ember/no-side-effects

      return state;
    },
    // probably not too safe, should only be used in tests
    reset() {
      this.get('keys').slice() // copy, so we dont mess with binding/loops
      .forEach(key => {
        delete this[key];
      });
      this.get('keys').clear();
    }
  });
  var _default = _exports.default = _service.default.extend({
    init() {
      this._super(...arguments);
      this._registry = Registry.create();
    },
    state: (0, _computed.readOnly)('_registry'),
    _panelFor(name) {
      return this.get("state.".concat(name));
    },
    _panels: (0, _object.computed)('state.keys.[]', function () {
      const keys = this.get('state.keys'),
        state = this.get('state');
      return keys.reduce((result, key) => {
        return result.addObject(state.get(key));
      }, (0, _array.A)([]));
    }),
    _panelsInGroup(name) {
      return this.get('_panels').filterBy('group.name', name);
    },
    open(name) {
      const panel = this._panelFor(name),
        group = panel.get('group');
      if (group && group.get('accordion')) {
        // if this is part of an accordion close
        // everything else
        this.closeAll(group.get('name'));
      }
      panel.set('apiOpenState', true);
      panel.set('apiWasUsed', true);
    },
    close(name) {
      this._panelFor(name).set('apiOpenState', false);
      this._panelFor(name).set('apiWasUsed', true);
    },
    toggle(name) {
      let panel = this._panelFor(name);
      return panel.get('isOpen') ? this.close(name) : this.open(name);
    },
    openAll(group) {
      this._panelsInGroup(group).forEach(panel => {
        panel.set('apiOpenState', true);
        panel.set('apiWasUsed', true);
      });
    },
    closeAll(group) {
      this._panelsInGroup(group).forEach(panel => {
        panel.set('apiOpenState', false);
        panel.set('apiWasUsed', true);
      });
    }
  });
});