define("ember-truth-helpers/helpers/is-empty", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function (_ref) {
    let [obj] = _ref;
    return (0, _utils.isEmpty)(obj);
  });
});