define("ember-resize-observer-polyfill/initializers/resize-observer-polyfill", ["exports", "resize-observer-polyfill"], function (_exports, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    if (typeof FastBoot === 'undefined' && !window.ResizeObserver) {
      window.ResizeObserver = _resizeObserverPolyfill.default;
    }
  }
  var _default = _exports.default = {
    initialize
  };
});