define("ember-network-state/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATES = _exports.CONFIG = void 0;
  const STATES = _exports.STATES = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
    RECONNECTING: 'RECONNECTING',
    LIMITED: 'LIMITED'
  };
  const CONFIG = _exports.CONFIG = {
    reconnect: {
      auto: false,
      path: '/favicon.ico',
      delay: 5000,
      multiplier: 1.5,
      timeout: 15000,
      maxDelay: 60000,
      maxTimes: -1
    }
  };
  var _default = _exports.default = {
    STATES,
    CONFIG
  };
});