(function() {


  // snippet from https://developers.intercom.com/installing-intercom/docs/basic-javascript#section-single-page-app
  // include placeholder as this is added later
  var APP_ID = "APP_ID";

  var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}};

  // wrap intercom in a module
  function vendorModule() {
    'use strict';

    return {
      default: function() {
        return window.Intercom.apply(null, arguments);
      },
      __esModule: true,
    };
  }

  define('intercom', [], vendorModule);
})();
