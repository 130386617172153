define("ember-ajax-fetch/services/fetch", ["exports", "@ember/service", "ember-ajax-fetch/mixins/fetch-request"], function (_exports, _service, _fetchRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class FetchService
   */
  const FetchService = _service.default.extend(_fetchRequest.default);
  var _default = _exports.default = FetchService;
});