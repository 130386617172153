define("ember-routing-utils/services/routing-utils", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RoutingUtilsService = _exports.default = (_class = class RoutingUtilsService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    /**
     * Retrieves all parameters for a `RouteInfo` object and its parents in
     * correct oder, so that you can pass them to e.g.
     * `transitionTo(routeName, ...params)`.
     *
     * @param routeInfo
     */
    getParameters(routeInfo) {
      let allParameters = [];
      let current = routeInfo;
      do {
        const {
          params,
          paramNames
        } = current;
        const currentParameters = paramNames.map(n => params[n]);
        allParameters = [...currentParameters, ...allParameters];
        // eslint-disable-next-line no-cond-assign
      } while (current = current.parent);
      return allParameters;
    }

    /**
     * Same as `getParameters`, but also includes the final `{ queryParams }` for
     * convenience.
     *
     * Retrieves all parameters for a `RouteInfo` object and its parents in
     * correct oder, so that you can pass them to e.g.
     * `transitionTo(routeName, ...params)`.
     *
     * @param routeInfo
     */
    getParametersWithQueryParameters(routeInfo) {
      return [...this.getParameters(routeInfo), {
        queryParams: routeInfo.queryParams
      }];
    }

    /**
     * Builds the URL for a `RouteInfo` object and its parents.
     *
     * @param routeInfo
     *
     * {@link https://github.com/emberjs/rfcs/issues/658}
     */
    getURLFromRouteInfo(routeInfo) {
      const {
        name,
        queryParams
      } = routeInfo;
      const orderedParameters = this.getParameters(routeInfo);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error Types don't support spreading here yet.
      const url = this.router.urlFor(name, ...orderedParameters, {
        queryParams
      });
      return url;
    }

    /**
     * Removes the `rootURL` from a URL, so that it can be used with
     * `transitionTo()`, because Ember handles this inconsistently. 🤡
     *
     * If the URL does not start with the `rootURL` or the app has no `rootURL`,
     * this just returns the original `url`.
     *
     * {@link https://github.com/emberjs/ember.js/issues/18422#issuecomment-538668443}
     */
    removeRootURL(url) {
      const {
        rootURL
      } = this.router;
      return rootURL && url.startsWith(rootURL) ? url.slice(rootURL.length - 1) : url;
    }

    /**
     * Prefixes the `rootURL` to a URL.
     *
     * If the URL already starts with the `rootURL` or the app has no `rootURL`,
     * this just returns the original `url`.
     *
     * {@link https://github.com/emberjs/ember.js/issues/18422#issuecomment-538668443}
     */
    prefixRootURL(url) {
      const {
        rootURL
      } = this.router;
      return rootURL && !url.startsWith(rootURL) ? "".concat(rootURL).concat(url) : url;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});