define("ember-cli-string-helpers/utils/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trim;
  function trim() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    if (typeof string !== 'string') {
      throw new TypeError("Expected a string, got a ".concat(typeof string));
    }
    return string.trim();
  }
});